var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-priceEstimation"
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('span', {
    staticClass: "text-wrap display-1 ma-3"
  }, [_vm._v(_vm._s(_vm.$t('PriceEstimation')))]), _vm.fileName ? _c('span', {
    staticClass: "text-wrap display-1 my-3"
  }, [_vm._v(" - " + _vm._s(_vm.fileName) + " ")]) : _vm._e(), _c('v-col', {
    attrs: {
      "align": "end"
    }
  }, [_c('v-btn', {
    staticClass: "text-none white--text",
    attrs: {
      "color": "orange"
    },
    on: {
      "click": _vm.openFileSelector
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "color": "white",
      "small": ""
    }
  }, [_vm._v(" fas fa-upload ")]), _vm._v(" " + _vm._s(_vm.$t('SelectPart')) + " ")], 1), _c('input', {
    ref: "selectPart",
    staticClass: "d-none",
    attrs: {
      "type": "file",
      "accept": ".stl,model/stl,.3mf"
    },
    on: {
      "change": _vm.fileSelected
    }
  })], 1)], 1), _c('v-divider', {
    staticClass: "mt-2 mb-6"
  }), _c('v-row', [_c('v-col', {
    class: {
      'd-flex': !_vm.partFile,
      'align-center': !_vm.partFile,
      'justify-center': !_vm.partFile
    },
    attrs: {
      "cols": _vm.fullscreen ? 12 : 8
    }
  }, [_c('v-card', [_vm.partFile ? _c('v-card-text', {
    staticClass: "pt-6"
  }, [_vm.partFile ? _c('Viewer3d', {
    attrs: {
      "part-file": _vm.partFile,
      "part": _vm.part,
      "background": "#ffffff",
      "mesh": "#9E9E9E",
      "toolbar": true,
      "clipping": true
    },
    on: {
      "fullscreen": _vm.switchFullscreen
    }
  }) : _vm._e()], 1) : _c('v-card-text', [_c('div', [!_vm.loading ? _c('v-btn', {
    staticClass: "text-none white--text",
    attrs: {
      "color": "orange"
    },
    on: {
      "click": _vm.openFileSelector
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "color": "white",
      "small": ""
    }
  }, [_vm._v(" fas fa-upload ")]), _vm._v(" " + _vm._s(_vm.$t('SelectPart')) + " ")], 1) : _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', {
    staticClass: "py-2",
    attrs: {
      "align": "center"
    }
  }, [_c('v-progress-circular', {
    staticClass: "ma-10",
    attrs: {
      "color": "orange",
      "size": "170",
      "width": 8,
      "indeterminate": ""
    }
  }, [_c('v-img', {
    attrs: {
      "src": require("../../assets/logo.png"),
      "contain": "",
      "aspect-ratio": "1",
      "width": "110"
    }
  })], 1)], 1)], 1)], 1)])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": _vm.fullscreen ? 6 : 4
    }
  }, [_c('v-expansion-panels', {
    attrs: {
      "multiple": ""
    },
    model: {
      value: _vm.expandedPanels,
      callback: function ($$v) {
        _vm.expandedPanels = $$v;
      },
      expression: "expandedPanels"
    }
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', [_c('span', {
    staticClass: "headerTitle"
  }, [_vm._v(_vm._s(_vm.$t('PartInformation')))]), _c('v-spacer'), _c('v-icon', [_vm._v("fas fa-info")])], 1), _c('v-expansion-panel-content', {
    attrs: {
      "px-5": ""
    }
  }, [_c('v-row', [_c('v-col', [_c('div', {
    staticClass: "text-center d-flex align-center justify-center mb-4"
  }, [_c('div', [_vm.part.size ? _c('span', {
    staticClass: "heightCube"
  }, [_vm._v(" " + _vm._s(_vm.part.size.height) + " mm ")]) : _c('span', {
    staticClass: "heightCube"
  }, [_vm._v("--- mm")]), _vm.part.size ? _c('span', {
    staticClass: "widthCube"
  }, [_vm._v(" " + _vm._s(_vm.part.size.width) + " mm ")]) : _c('span', {
    staticClass: "widthCube"
  }, [_vm._v("--- mm")]), _vm.part.size ? _c('span', {
    staticClass: "depthCube"
  }, [_vm._v(" " + _vm._s(_vm.part.size.depth) + " mm ")]) : _c('span', {
    staticClass: "depthCube"
  }, [_vm._v("--- mm")]), _c('v-img', {
    staticClass: "imageCube",
    attrs: {
      "src": require("../../../public/img/icons/cube_size_arrows-svg.svg"),
      "alt": "cube sizes",
      "max-width": "130px",
      "height": "auto"
    }
  })], 1)])]), _c('v-col', [_c('div', {
    staticClass: "justify-center"
  }, [_c('div', [_c('v-img', {
    attrs: {
      "src": require("../../../public/img/icons/volume.png"),
      "alt": "volume",
      "max-height": "110px",
      "contain": ""
    }
  })], 1), _c('div', [_vm.part.volume ? _c('span', {
    staticClass: "volume"
  }, [_vm._v(" " + _vm._s(_vm.improveVolumeReadability(_vm.part.volume)) + " ")]) : _c('span', {
    staticClass: "volume"
  }, [_vm._v("--- cm³")])])])])], 1), _vm.part.volume ? [_c('v-divider'), _c('v-row', {
    staticClass: "mt-5"
  }, [_c('v-col', [_c('v-slider', {
    staticClass: "mt-5",
    attrs: {
      "label": _vm.$t('infill'),
      "thumb-color": "primary",
      "thumb-label": "always",
      "disabled": _vm.sliceInProgress
    },
    model: {
      value: _vm.sliceParameters.infill,
      callback: function ($$v) {
        _vm.$set(_vm.sliceParameters, "infill", $$v);
      },
      expression: "sliceParameters.infill"
    }
  }), _c('v-slider', {
    staticClass: "mt-5",
    attrs: {
      "label": _vm.$t('layerHeight'),
      "max": 0.4,
      "min": 0.1,
      "step": "0.1",
      "thumb-label": "always",
      "disabled": _vm.sliceInProgress
    },
    model: {
      value: _vm.sliceParameters.layer_height,
      callback: function ($$v) {
        _vm.$set(_vm.sliceParameters, "layer_height", $$v);
      },
      expression: "sliceParameters.layer_height"
    }
  }), _c('v-btn', {
    staticClass: "text-none pa-0",
    attrs: {
      "ripple": false,
      "color": "primary",
      "block": "",
      "disabled": _vm.sliceInProgress
    },
    on: {
      "click": _vm.slicePart
    }
  }, [_vm._v(" Slice ")])], 1)], 1), _c('v-divider', {
    staticClass: "my-5"
  }), _vm.sliceInProgress ? _c('v-row', [_c('v-col', [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": "",
      "color": "primary"
    }
  }, [_vm._v(" fas fa-spinner fa-spin ")]), _vm._v(" " + _vm._s(_vm.$t('slicingInProgress')) + " " + _vm._s(_vm.sliceProgress * 100 / 200) + "% ")], 1)], 1) : _c('v-row', [_c('v-col', [_c('v-row', {
    staticClass: "flex-column"
  }, [_c('v-col', [_vm._v(_vm._s(_vm.$t('partVolume')) + ": " + _vm._s(_vm.improveVolumeReadability(_vm.part.slice.partVolume)))]), _c('v-col', [_vm._v(_vm._s(_vm.$t('supportVolume')) + ": " + _vm._s(_vm.improveVolumeReadability(_vm.part.slice.supportVolume)))]), _c('v-col', [_vm._v(_vm._s(_vm.$t('totalVolume')) + ": " + _vm._s(_vm.improveVolumeReadability(_vm.part.slice.volumeTotal)))]), _c('v-col', [_vm._v(_vm._s(_vm.$t('estimatedTime')) + ": " + _vm._s(`${Math.floor(_vm.part.slice.estimatedPrintTime / 3600)}h ${Math.floor(_vm.part.slice.estimatedPrintTime % 3600 / 60)}m ${Math.floor(_vm.part.slice.estimatedPrintTime % 3600 % 60)}s`))])], 1)], 1), _c('v-col', [_c('v-card', {
    staticClass: "mx-auto"
  }, [_c('v-card-title', [_vm._v(_vm._s(_vm.$t('previewGCode')))]), _c('v-card-subtitle', [_c('v-icon', {
    attrs: {
      "x-small": "",
      "color": "warning"
    }
  }, [_vm._v(" fas fa-exclamation-triangle ")]), _vm._v(" " + _vm._s(_vm.$t('doNotUseForProduction')) + " ")], 1), _c('v-card-text', [_c('v-btn', {
    staticClass: "text-none pa-0 mb-2",
    attrs: {
      "block": "",
      "outlined": "",
      "ripple": false,
      "href": _vm.gcodeWithSupport,
      "download": `Output_With_Support.gcode`
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "x-small": "",
      "color": "primary"
    }
  }, [_vm._v(" fas fa-download ")]), _vm._v(_vm._s(_vm.$t('withSupport')) + " ")], 1), _c('v-btn', {
    staticClass: "text-none pa-0 mb-2",
    attrs: {
      "block": "",
      "outlined": "",
      "ripple": false,
      "href": _vm.gcodeWithoutSupport,
      "download": `Output_Without_Support.gcode`
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "x-small": "",
      "color": "primary"
    }
  }, [_vm._v(" fas fa-download ")]), _vm._v(_vm._s(_vm.$t('withoutSupport')) + " ")], 1)], 1)], 1)], 1)], 1)] : _vm._e()], 2)], 1), _vm.part.volume ? _c('v-row', {
    staticClass: "mt-2"
  }, [_c('v-col', [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "width": "100%",
      "color": "info"
    },
    on: {
      "click": _vm.openSuppliersPricing
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-city ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('SuppliersPricing')) + " ")])], 1)], 1)], 1) : _vm._e(), _vm.estimatedPrices ? _c('v-expansion-panel', [_c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('PriceEstimatesTitle')) + " ")]), _c('v-card-subtitle', [_vm._v(" " + _vm._s(_vm.$t('EstimatedPrices')) + " ")]), _c('v-card-text', [_c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-slider', {
    staticClass: "align-center",
    attrs: {
      "max": 100,
      "min": 0,
      "label": _vm.$t('margin'),
      "thumb-label": "always"
    },
    on: {
      "change": _vm.changeMargin
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [_c('v-text-field', {
          staticClass: "mt-0 pt-0",
          staticStyle: {
            "width": "60px"
          },
          attrs: {
            "max": 100,
            "min": 0,
            "type": "number"
          },
          on: {
            "change": _vm.changeMargin
          },
          model: {
            value: _vm.defaultMargin,
            callback: function ($$v) {
              _vm.defaultMargin = $$v;
            },
            expression: "defaultMargin"
          }
        })];
      },
      proxy: true
    }], null, false, 4057278075),
    model: {
      value: _vm.defaultMargin,
      callback: function ($$v) {
        _vm.defaultMargin = $$v;
      },
      expression: "defaultMargin"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', {
    attrs: {
      "label": _vm.$t('useVolumeFrom'),
      "items": [{
        text: _vm.$t('thePart'),
        value: 'part',
        disabled: false
      }, {
        text: _vm.$t('thePartSliceWithoutSupport'),
        value: 'sliceWithoutSupport',
        disabled: _vm.sliceInProgress
      }, {
        text: _vm.$t('thePartSliceIncludingSupport'),
        value: 'sliceIncludingSupport',
        disabled: _vm.sliceInProgress
      }],
      "variant": "outlined"
    },
    on: {
      "change": _vm.getEstimatedPrices
    },
    model: {
      value: _vm.estimatedPriceVolumeOrigin,
      callback: function ($$v) {
        _vm.estimatedPriceVolumeOrigin = $$v;
      },
      expression: "estimatedPriceVolumeOrigin"
    }
  })], 1)], 1)], 1)], 1), _c('PriceEstimates', {
    attrs: {
      "part": _vm.part,
      "prices": _vm.estimatedPricesMargin
    }
  })], 1)], 1) : _vm._e()], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "content-class": "page-priceEstimation dialogSuppliersPricing",
      "max-width": "90%",
      "min-height": "90%"
    },
    model: {
      value: _vm.suppliersPricingModal,
      callback: function ($$v) {
        _vm.suppliersPricingModal = $$v;
      },
      expression: "suppliersPricingModal"
    }
  }, [_vm.suppliers.length && _vm.availablePrinters ? _c('PartDetailedSuppliersPrices', {
    attrs: {
      "part": _vm.part,
      "suppliers": _vm.suppliers,
      "printers": _vm.availablePrinters
    },
    on: {
      "close": _vm.closeSuppliersPricing
    }
  }) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }