<template lang="html" src="./pagePriceEstimation.template.vue"></template>

<style lang="scss" src="./pagePriceEstimation.scss"></style>

<script>
import {CuraWASM} from 'cura-wasm';
import {resolveDefinition} from 'cura-wasm-definitions';


import { PriceEstimates, Viewer3d, Utils3MF, UtilsSTL, UtilsBLS } from '@cloudmanufacturingtechnologies/portal-components';
import PartDetailedSuppliersPrices from '../../components/partDetailedSuppliersPrices/PartDetailedSuppliersPrices.vue';

const i18nData = require('./pagePriceEstimation.i18n');

export default {
  name: 'PagePriceEstimation',
  components: {
    Viewer3d,
    PartDetailedSuppliersPrices,
    PriceEstimates,
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      /**
       * Main variable
       */
      part: {
        size: {
          width: 0,
          depth: 0, 
          height: 0,
        },
        nestingSize: {
          width: 0,
          depth: 0, 
          height: 0,
        },
        volume: 0,
        slice: {
          volumeTotal: 0,
          partVolume: 0,
          supportVolume: 0,
          estimatedPrintTime: 0,
        },
        rotationHistory: [{matrix: [0,0,0]}],
        criticalDimensions: []
      },
      /**
       * Panel
       */
      expandedPanels: [0, 1],
      /**
       * Prices
       */
      estimatedPrices: null,
      estimatedPricesMargin: null,
      defaultMargin: 12,
      /**
       * File variable
       */
      partFile: null,
      fileName: null,
      /**
       * 3DViewer variable
       */
      fullscreen: false,
      loading: false,
      partGeometry: null,
      /**
       * Supplier pricing
       */
      suppliersPricingModal: false,
      suppliers: [],
      availablePrinters: null,
      /**
       * Slicer Variable
       */
      sliceInProgress: false,
      gcodeWithoutSupport: null,
      gcodeWithSupport: null,
      sliceParameters: {
        infill: 17,
        layer_height: 0.2,
      },
      sliceProgress: 0,
      /**
       * Estimated Price Volume Data
       * - part
       * - sliceIncludingSupport
       * - sliceWithoutSupport
       */
      estimatedPriceVolumeOrigin: 'part'
    };
  },
  created() {
    this.getSuppliers();
    this.getPrinters();
  },
  methods: {
    getSuppliers() {
      this.$apiInstance.getAllSuppliers().then(data => {
        this.suppliers = data;
      });
    },
    getPrinters() {
      this.$apiInstance.getPrinters().then(data => {
        this.availablePrinters = data;
      });
    },
    openSuppliersPricing() {
      this.suppliersPricingModal = true;
    },
    closeSuppliersPricing() {
      this.suppliersPricingModal = false;
    },
    openFileSelector() {
      const fileSelector = this.$refs.selectPart;
      fileSelector.value = '';
      fileSelector.click();
    },
    fileLoader(file) {
      this.loading = true;
      this.partFile = null;
      this.fullscreen = false;
      this.partVolume = null;
      this.partSize = null;
      this.fileName = null;
      const splittedFileName = file.name.split('.');
      const extension = splittedFileName.pop().toLocaleLowerCase();
      if (
        splittedFileName.length > 0 &&
        extension === 'stl'
      ) {
        this.fileName = file.name;
        const fileReader = new FileReader();
        fileReader.addEventListener('loadend', () => {
          setTimeout(() => {
            this.partFile = 
              {
                buffer: Buffer.from(fileReader.result),
                extension: 'stl'
              };
            this.loading = false;
            this.loadGeometry();
            if (!this.expandedPanels.includes(2)) {
              this.expandedPanels.push(2);
            }
          }, 200);
        });
        fileReader.readAsArrayBuffer(file);
      } else if (
        splittedFileName.length > 0 && 
        extension === '3mf'
      ) {
        this.convert3MFtoSTL(file);
      }
    },
    convert3MFtoSTL(file) {
      /**
       * Read the 3mf file
       */
      const fileReader = new FileReader();
      fileReader.addEventListener('loadend', () => {
        setTimeout(() => {
          const partFile3mf = 
            {
              buffer: Buffer.from(fileReader.result),
              extension: '3mf'
            };
          /**
           * Convert 3mf to STL
           */
          const skeleton3mf = Utils3MF.load3mfAsJsonObject(partFile3mf.buffer);
          const convertedFile = UtilsSTL.skeleton3mfToSTL(skeleton3mf);
          const blobFile = new Blob([convertedFile], {
            type: 'octet/stream',
          });
          /**
           * Set a name for the fileLoader
           */
          blobFile['name'] = `${file.name}.stl`;
          this.fileLoader(blobFile);
        }, 200);
      });
      fileReader.readAsArrayBuffer(file);
    },
    fileSelected() {
      const fileSelector = this.$refs.selectPart;
      if (fileSelector.value && fileSelector.files.length > 0) {
        const file = fileSelector.files[0];
        this.fileLoader(file);
      }
    },
    getSliceCommandline(machine_width = 430, machine_depth = 320, machine_height = 350, support_enable = true, translatePartOnZ = 0) {
      const infill_line_distance = this.sliceParameters.infill <= 1 ? (0.4 * 100) / 1 * 2 : (0.4 * 100) / this.sliceParameters.infill * 2 ;
      return `slice -v -j definitions/fdmprinter.def.json -s machine_width=${machine_width} -s machine_depth=${machine_depth} -s machine_height=${machine_height} -s machine_gcode_flavor=marlin -s machine_nozzle_size=0.4 -s layer_height=${this.sliceParameters.layer_height} -s infill_line_distance=${infill_line_distance} -s infill_pattern=grid -s support_enable=${support_enable} -s support_structure=normal -s support_type=buildplate -s mesh_position_z=${translatePartOnZ} -s roofing_layer_count=0 -s machine_disallowed_areas=[] -s roofing_monotonic=true -o Model.gcode -l Model.stl`;
    },
    async slicePart() {
      /**
       * Slicer only works with STL file. Convert other file format to STL before slicing
       */
      if(this.partFile.extension === 'stl') {
        this.sliceInProgress = true;
        this.sliceProgress = 0;
        const bufferGeom = UtilsSTL.loadSTLAsBufferGeometry(this.partFile.buffer);
        /**
         * Calculation for translate part on Z for slicing
         * Don't center the geometry to detect Z distance correctly (bufferGeom.center();)
         */
        const translatePartOnZ = bufferGeom.boundingBox.min.z === 0 ? 0 : -bufferGeom.boundingBox.min.z;
        // console.log(`Translate on Z: ${translatePartOnZ}`);
        /**
         * Slice with support
         */
        // console.log(this.getSliceCommandline(430, 320, 350, true, translatePartOnZ));
        const slicer = new CuraWASM({
          command: this.getSliceCommandline(430, 320, 350, true, translatePartOnZ),
          definition: resolveDefinition('ultimaker2'),
          overrides: null,
          transfer: null
        });

        slicer.on('progress', percent =>
        {
          this.sliceProgress = percent;
          // console.log(`slicer 1: ${percent}%`);
        });

        const sliceData = await slicer.slice(this.partFile.buffer, this.partFile.extension)
          .then(data=>{
            return data;
          });

        /**
         * Slice Without support
         */
      
        // console.log(this.getSliceCommandline(430, 320, 350, false, translatePartOnZ));
        const slicerWithoutSupport = new CuraWASM({
          command: this.getSliceCommandline(430, 320, 350, false, translatePartOnZ),
          definition: resolveDefinition('ultimaker2'),
          overrides: null,
          transfer: null
        });

        slicerWithoutSupport.on('progress', percent =>
        {
          /**
           * We use 2 slicer, the progress is based on 200% (100% per slicer)
           */
          this.sliceProgress = 100 + percent;
        });

        const sliceDataWithoutSupport = await slicerWithoutSupport.slice(this.partFile.buffer, this.partFile.extension)
          .then(data=>{
            return data;
          });
        // console.log('metadata:');
        // console.log(sliceData);
        // console.log('metadata without support:');
        // console.log(sliceDataWithoutSupport);

        this.part.slice.volumeTotal = sliceData.metadata.filamentUsage;
        this.part.slice.partVolume = sliceDataWithoutSupport.metadata.filamentUsage;
        this.part.slice.supportVolume = this.part.slice.volumeTotal - this.part.slice.partVolume;
        this.part.slice.estimatedPrintTime = sliceData.metadata.printTime;

        this.gcodeWithoutSupport = URL.createObjectURL(
          new Blob([sliceDataWithoutSupport.gcode], {
            type: 'octet/stream'
          })
        );

        this.gcodeWithSupport = URL.createObjectURL(
          new Blob([sliceData.gcode], {
            type: 'octet/stream'
          })
        );

        // console.log(this.gcodeWithoutSupport);
        // console.log(this.gcodeWithSupport);

        // console.log(this.part.slice);

        this.sliceInProgress = false;

        await slicer.destroy();
        await slicerWithoutSupport.destroy();
      }
      this.getEstimatedPrices();
    },
    switchFullscreen() {
      this.fullscreen = !this.fullscreen;
    },
    loadGeometry() {
      if(this.partFile.extension === 'stl') {
        const bufferGeom = UtilsSTL.loadSTLAsBufferGeometry(this.partFile.buffer);
        bufferGeom.center();
        bufferGeom.computeVertexNormals();
        bufferGeom.computeBoundingBox();
        this.partGeometry = bufferGeom;
        this.getPartSize();
        this.getPartVolume();
      } else if(this.partFile.extension === '3mf') {
        const bufferGeom = Utils3MF.load3mfAsBufferGeometry(this.partFile.buffer);
        bufferGeom.center();
        bufferGeom.computeVertexNormals();
        bufferGeom.computeBoundingBox();
        this.partGeometry = bufferGeom;
        this.getPartSize();
        this.getPartVolume();
      }
      this.getEstimatedPrices();
      this.slicePart();
    },
    getEstimatedPrices() {
      let volume = this.part.volume;
      if(this.estimatedPriceVolumeOrigin === 'sliceIncludingSupport') {
        volume = this.part.slice.volumeTotal;
      }else if(this.estimatedPriceVolumeOrigin === 'sliceWithoutSupport') {
        volume = this.part.slice.partVolume;
      }
      const AdminEstimatedPricesBody = new this.$BcmModel.AdminEstimatedPricesBody(
        this.part.size,
        this.part.nestingSize,
        volume
      );
      this.$apiInstance
        .getAdminEstimatedPrices({part: AdminEstimatedPricesBody})
        .then(
          (estimatedPrices) => {
            /**
             * Cleanup series for DMLS
             */
            if (estimatedPrices && estimatedPrices.DMLS) {
              Object.keys(estimatedPrices.DMLS).forEach((material) => {
                if (estimatedPrices.DMLS[material] && material !== 'printersSizeCompatible') {
                  /**
                   * It's not possible to set minimum price (series) to null, we just copy the unitary prices for series
                   */
                  estimatedPrices.DMLS[material].min =
                    estimatedPrices.DMLS[material].max;
                }
              });
            }
            this.estimatedPrices = estimatedPrices;
            this.defaultMargin = 12;
            this.estimatedPricesMargin = JSON.parse(JSON.stringify(this.estimatedPrices));
            this.changeMargin();

          },
          (error) => {
            /**
             * ERROR GET PART VIWER3D FILE METADATA
             */
          }
        );
    },
    changeMargin() {
      for (const technology in this.estimatedPricesMargin) {
        for (const material in this.estimatedPricesMargin[technology]) {
          if(material === 'printersSizeCompatible') {
            continue;
          }
          if (this.estimatedPrices[technology][material]) {
            this.estimatedPricesMargin[technology][material].min = Math.ceil(this.estimatedPrices[technology][material].min * (1 + (this.defaultMargin / 100)));
            this.estimatedPricesMargin[technology][material].max = Math.ceil(this.estimatedPrices[technology][material].max * (1 + (this.defaultMargin / 100)));
          } else {
            this.estimatedPricesMargin[technology][material] = null;
          }
        } 
      }
    },
    getPartSize() {
      const bb = this.partGeometry.boundingBox;
      this.part.size = {
        width: Math.round(bb.max.x - bb.min.x),
        depth: Math.round(bb.max.y - bb.min.y),
        height: Math.round(bb.max.z - bb.min.z),
      };
      this.part.nestingSize = this.part.size;
    },
    /**
     * Analyse Geometry
     */
    getPartVolume() {
      const coordinates = this.splitRawData(
        this.partGeometry.attributes.position.array
      );
      const precision = 10000;
      const triangles = this.constructTriangles(coordinates);
      this.part.volume = this.volumeOfPart(triangles, precision);
    },

    splitRawData(data) {
      const cX = [];
      const cY = [];
      const cZ = [];

      for (let i = 0; i < data.length; i++) {
        if (i % 3 === 0) {
          cX.push(data[i]);
        } else if (i % 3 === 1) {
          cY.push(data[i]);
        } else {
          cZ.push(data[i]);
        }
      }

      return [cX, cY, cZ];
    },

    /**
     * Construct triangles from split raw coordinates
     */
    constructTriangles(coordinates) {
      const cX = coordinates[0];
      const cY = coordinates[1];
      const cZ = coordinates[2];

      const triangles = [];

      for (let i = 0; i < cX.length; i = i + 3) {
        triangles[i / 3] = [
          [cX[i], cY[i], cZ[i]],
          [cX[i + 1], cY[i + 1], cZ[i + 1]],
          [cX[i + 2], cY[i + 2], cZ[i + 2]],
        ];
      }

      return triangles;
    },

    /**
     * Compute volume for a single triangle of the mesh
     */
    volumeOfTriangle(triangle) {
      const v321 = triangle[2][0] * triangle[1][1] * triangle[0][2]; //p3.X*p2.Y*p1.Z;
      const v231 = triangle[1][0] * triangle[2][1] * triangle[0][2]; //p2.X*p3.Y*p1.Z;
      const v312 = triangle[2][0] * triangle[0][1] * triangle[1][2]; //p3.X*p1.Y*p2.Z;
      const v132 = triangle[0][0] * triangle[2][1] * triangle[1][2]; //p1.X*p3.Y*p2.Z;
      const v213 = triangle[1][0] * triangle[0][1] * triangle[2][2]; //p2.X*p1.Y*p3.Z;
      const v123 = triangle[0][0] * triangle[1][1] * triangle[2][2]; //p1.X*p2.Y*p3.Z;

      return (-v321 + v231 + v312 - v132 - v213 + v123) / 6.0;
    },

    /**
     * Compute the total volume of a part from its triangles
     */
    volumeOfPart(triangles, precision) {
      let volume = 0;
      for (const triangle of triangles) {
        volume += this.volumeOfTriangle(triangle);
      }

      return Math.round(Math.abs(volume) * precision) / precision;
    },
    improveVolumeReadability(volumeInMillimeter) {
      let improvedValue = 0;
      switch (true) {
      case volumeInMillimeter < 1e3:
        improvedValue = `${Math.ceil(volumeInMillimeter)} mm³`;
        break;
      case volumeInMillimeter >= 1e3 && volumeInMillimeter < 1e6:
        improvedValue = `${
          Math.ceil((volumeInMillimeter / 1e3) * 1e2) / 1e2
        } cm³`;
        break;
      case volumeInMillimeter >= 1e6 && volumeInMillimeter < 1e9:
        improvedValue = `${
          Math.ceil((volumeInMillimeter / 1e6) * 1e2) / 1e2
        } dm³`;
        break;
      case volumeInMillimeter >= 1e9:
        improvedValue = `${
          Math.ceil((volumeInMillimeter / 1e9) * 1e2) / 1e2
        } m³`;
        break;
      default:
        improvedValue = 'value';
      }
      return improvedValue;
    },
  },
};
</script>
